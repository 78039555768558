<template>
  <div>
    <b-card>
      <b-row>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label-size=""
            label-for="title"
          >
            <template #label>
              <h6>{{ $t('classes.class_title') }}</h6>
            </template>
            <b-form-input
              id="title"
              v-model="oneClass.title"
              readonly
              :placeholder="$t('classes.class_title')"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label-for="max_students"
          >
            <template #label>
              <h6>{{ $t('classes.capacity') }}</h6>
            </template>
            <b-form-input
              id="max_students"
              v-model="oneClass.capacity"
              readonly
              type="number"
              :placeholder="$t('classes.capacity')"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="3"
          class="mt-2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            size="sm"
            @click="editClass"
          >
            <feather-icon
              icon="EditIcon"
              size="20"
            />
          </b-button>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="openModal()"
          >
            <feather-icon
              icon="PlusIcon"
            />
            {{ $t('general.add') }}
          </b-button>
        </b-col>
        <b-col
          cols="12"
          class="mt-2"
        >
          <b-table
            hover
            show-empty
            responsive
            :items="GET_CLASS_COURSE.results"
            :fields="fields"
            class="mb-0"
            :empty-text="$t('general.no_records_to_show')"
          >
            <template #head()="{ label}">
              {{ $t(label) }}
            </template>
            <template #cell(access)="{ item }">
              {{ $_dateTimeFormatter(item.access_start_date).split(',')[0] }} - {{ $_dateTimeFormatter(item.access_end_date).split(',')[0] }}
            </template>
            <template #cell(acces)="data">
              <b-form-checkbox
                :checked="data.value"
                disabled
                inline
              />
            </template>
            <template #cell(course)="data">
              {{ data.value.title }}
            </template>
            <template #cell(teachers)="data">
              <p
                v-for="item in data.value"
                :key="item.id"
              >
                {{ item.full_name }}
              </p>
            </template>

            <template #cell(is_active)="data">
              <b-form-checkbox
                :checked="data.value"
                name="check-button"
                switch
                inline
                disabled
              />
            </template>
            <template
              #cell(actions)="data"
            >
              <!-- <div class="d-flex align-items-center no-wrap"> -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="btn-icon mr-50 p-25"
                size="sm"
                @click="openEditCourseAttachmentModal(data.item)"
              >
                <feather-icon
                  icon="EditIcon"
                  size="22"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-icon p-25"
                size="sm"
                @click="deleteCourse(data.item.id)"
              >
                <feather-icon
                  size="22"
                  icon="XIcon"
                />
              </b-button>
              <!-- </div> -->
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <!-- <AddCourseModal :def-courses="defCourses" /> -->
    <enroll-new-course-modal
      :render-increment="modal_render_increment"
      :courses="GET_COURSES_LIST.results"
      :enrolled-courses="all_enrolled_courses"
      :search-results="GET_COURSES_LIST.results"
      @search="searchCourses"
      @scrollEnd="fetchNewCourses"
      @saveClick="addNewCourses"
    />
    <edit-course-attachment-modal
      :course-item="course_attachment_to_edit"
      @saveClick="editCourseAttachment"
    />
    <AddEditClass
      :def-data="defData"
      :is-edit="isEdit"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import {
  BCol,
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { required } from '@validations'
// import AddCourseModal from './classInfoModals/AddCourseModal.vue'
import areYouSure from '@/mixins/areYouSure'
import toast from '@/mixins/toast'
import AddEditClass from './classInfoModals/AddEditClass.vue'
import enrollNewCourseModal from '../../Users/components/modals/enrollNewCourseModal.vue'
import editCourseAttachmentModal from '../../Users/components/modals/editCourseAttachmentModal.vue'
import extra from '@/mixins/extra'

export default {
  components: {
    BCol,
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    // AddCourseModal,
    BFormGroup,
    BFormInput,
    BRow,
    AddEditClass,
    enrollNewCourseModal,
    editCourseAttachmentModal,
  },
  directives: {
    Ripple,
  },
  mixins: [areYouSure, toast, extra],
  data() {
    return {
      oneClass: {
        title: '',
        start_time: null,
        end_time: null,
        capacity: null,
      },
      isEdit: false,
      defData: {},
      fields: [
        {
          label: 'classes.course_name',
          key: 'course',
        },
        {
          label: 'users.teachers',
          key: 'teachers',
        },
        {
          label: 'users.access',
          key: 'access',
        },
        // {
        //   label: 'Дата начала доступа',
        //   key: 'start_date',
        // },
        // {
        //   label: 'Дата окончение доступа',
        //   key: 'end_date',
        // },
        // {
        //   label: 'Доступ',
        //   key: 'acces',
        // },
        {
          label: 'general.actions',
          key: 'actions',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      required,
      defCourses: [],
      query_params: {
        search: '',
        page: 1,
        page_size: 5,
      },
      items: [],
      modal_render_increment: 0,
      course_attachment_to_edit: {
        teachers: [],
        access: {},
      },
      all_enrolled_courses: [],
      delay: 300,
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_CLASS_COURSE', 'GET_COURSES_LIST']),
  },
  mounted() {
    this.FETCH_CLASS(this.$route.params.id)
      .then(res => {
        this.oneClass = res
        this.updateBreadcrumb()
      })
      .catch(() => {
        this.$router.go(-1)
        this.$_errorToast(this.$t('general.connection_error'))
      })
    this.FETCH_CLASS_COURSE({ group: this.$route.params.id })
    this.FETCH_COURSES_LIST(this.query_params).then(() => {
      this.modal_render_increment += 1
    })
  },
  methods: {
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    ...mapActions('courses', [
      'FETCH_CLASS_COURSE',
      'DELETE_CLASS_COURSE',
      'CREATE_CLASS_COURSE',
      'UPDATE_CLASS_COURSE',
      'FETCH_COURSES_LIST',
    ]),
    ...mapActions('classes', [
      'FETCH_CLASS',
    ]),
    refresh() {
      this.FETCH_CLASS(this.$route.params.id)
        .then(res => {
          this.oneClass = res
        })
    },
    openModal() {
      // this.defCourses = this.GET_CLASS_COURSE.results.map(el => el.course.id)
      this.$nextTick(() => {
        this.$bvModal.show('enroll-new-course-modal')
      })
    },
    editClass() {
      this.defData = this.oneClass
      this.isEdit = true
      this.$nextTick(() => {
        this.$bvModal.show('AddEditClassModal')
      })
    },
    updateBreadcrumb() {
      const [first, second] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      second.text = this.oneClass.title
      this.UPDATE_BREADCRUMB([first, second])
    },
    // delete course
    deleteCourse(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_CLASS_COURSE(id)
            .then(() => {
              this.$_successToast(this.$t('general.removed'))
              this.FETCH_CLASS_COURSE({ group: this.$route.params.id })
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
    searchCourses(params) {
      // this.query_params.search = text
      this.FETCH_COURSES_LIST(params)
    },
    fetchNewCourses() {
      if (this.GET_COURSES_LIST.next) {
        this.query_params.page += 1
        this.FETCH_COURSES_LIST(this.query_params).then(() => {
          this.modal_render_increment += 1
        })
      }
    },
    addNewCourses(reqBody) {
      const newReqBody = reqBody.map(el => ({
        group: this.$route.params.id,
        is_individual: false,
        ...el,
      }))
      this.CREATE_CLASS_COURSE({ objects: newReqBody }).then(() => {
        this.$_successToast(this.$t('general.success'))
        this.$bvModal.hide('enroll-new-course-modal')
        this.FETCH_CLASS_COURSE({ group: this.$route.params.id })
      })
    },
    openEditCourseAttachmentModal(item) {
      const {
        id, access_start_date, access_end_date, teachers, course,
      } = item
      this.course_attachment_to_edit = {
        id,
        access: { start: access_start_date, end: access_end_date },
        teachers: teachers.map(el => el.id),
        course: course.id,
      }
      this.$bvModal.show('edit-course-attachment-modal')
    },
    editCourseAttachment(courseAttachment) {
      this.UPDATE_CLASS_COURSE(courseAttachment).then(() => {
        this.$_successToast(this.$t('general.edited'))
        this.FETCH_CLASS_COURSE({ group: this.$route.params.id })
      })
        .catch(() => {
          this.$_errorToast(this.$t('general.error'))
        })
    },
  },
}
</script>

<style lang="scss">

</style>
