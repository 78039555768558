export default {
  methods: {
    $_moneyFormatter(num) {
      return new Intl.NumberFormat('ru-Ru', { style: 'currency', currency: 'UZS' }).format(num)
    },
    $_dateTimeFormatter(date) {
      return new Date(date).toLocaleString()
    },
    $_phoneNumberFormatter(phone) {
      if (phone) {
        const phoneValue = phone.replace(/-/g, '').match(/(\d{1,12})/g)
        return phoneValue[0].replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/g, '+$1-$2-$3-$4-$5')
      }
      return ''
    },
    $_convertToBase64(element) {
      return new Promise((resolve, reject) => {
        const file = element
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function () {
          resolve(reader.result)
        }
        reader.onerror = error => reject(error)
      })
    },
  },
}
