<template>
  <b-modal
    id="edit-course-attachment-modal"
    :title="$t('users.edit_course_attachment')"
    :cancel-title="$t('general.cancel')"
    :ok-title="$t('general.save')"
    no-close-on-backdrop
    centered
    @ok="saveClick"
  >
    <validation-observer ref="courseAttachValidations">
      <b-form-group
        :label="$t('users.teachers')"
        label-for="teacher-select"
      >
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('users.teachers')"
        >
          <v-select
            id="teacher-select"
            v-model="course_attachment.teachers"
            :options="GET_ONE_COURSE.teachers"
            label="full_name"
            multiple
            :reduce="opt => opt.id"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group :label="$t('users.access')">
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('users.access')"
        >
          <date-picker
            v-model="course_attachment.access"
            :other-props="{'is-range': true}"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BModal, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { required } from '@validations'
import datePicker from '@/components/VueDatePicker.vue'

export default {
  components: {
    BModal,
    BFormGroup,
    vSelect,
    datePicker,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    courseItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      course_attachment: {
        teachers: [],
        access: {},
      },
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_ONE_COURSE']),
  },
  watch: {
    courseItem() {
      this.course_attachment = this.courseItem
      this.fetchTeacherOptions(this.courseItem.course)
    },
  },
  methods: {
    ...mapActions('courses', ['FETCH_ONE_COURSE']),
    saveClick() {
      this.$refs.courseAttachValidations.validate().then(success => {
        if (success) {
          const { access, ...rest } = this.course_attachment
          this.$emit('saveClick', { access_start_date: access.start, access_end_date: access.end, ...rest })
        }
      })
    },
    fetchTeacherOptions(id) {
      this.FETCH_ONE_COURSE(id)
    },
  },
}
</script>

<style>

</style>
